import React from 'react';
import HeaderBanner from './HeaderBanner';

const Home = () => {
    return (
        <div>
            <HeaderBanner />
        </div>
    );
};

export default Home;